import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    section,
    grid,
    titleText,
    subtitleText,
    list,
    item,
    image,
    nameText,
    specText,
} from './people-presentation.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

const img1Src = '../../assets/images/about_03.png';
const img2Src = '../../assets/images/about_04.png';

interface IPresentationProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const PeoplePresentation: React.FC<IPresentationProps> = ({ className = '', TitleTag }) => {
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${section}`}
            TitleTag={TitleTag}
            title={'Poznaj Nasz Zespół'}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
            }}
            css={css}
            style={style}
        >
            <ul className={list}>
                <li className={item}>
                    <StaticImage
                        className={image}
                        src={img1Src}
                        alt={'Sylwia Pogorzelska'}
                        quality={100}
                    />
                    <p className={nameText}>Sylwia Pogorzelska</p>
                    <p className={specText}>ARCHITEKT WNĘTRZ</p>
                </li>
                <li className={item}>
                    <StaticImage
                        className={image}
                        src={img2Src}
                        alt={'Magdalena Śliwka'}
                        quality={100}
                    />
                    <p className={nameText}>Magdalena Śliwka</p>
                    <p className={specText}>Architekt</p>
                </li>
            </ul>
        </Section>
    );
};

export default PeoplePresentation;
